<template>
  <div>
    <div ref="container"></div>
    <el-dialog :title="`序号${currBracelet.index||''}`" :visible.sync="dialogVisible" :show-close="false" width="90%">
      <div style="display: flex;align-items: center;justify-content: center">
        <div style="display: flex;align-items: center;flex: 1;justify-content: flex-end">
          <el-image style="width: 25px;height: 25px;" :src="'../assets/user_code.png'" mode="contain">
          </el-image>
          <span class="notice-title">编号</span>
        </div>
        <div class="notice-content" style="font-size: 14px">{{ currBracelet.code|codeFilter }}</div>
      </div>
      <div style="display: flex;align-items: center;margin-top: 2vh;justify-content: center">
        <div style="display: flex;align-items: center;flex: 1;justify-content: flex-end">
          <el-image style="width: 25px;height: 25px;" :src="'../assets/user_step.png'" mode="contain">
          </el-image>
          <span class="notice-title">步数</span>
        </div>
        <div class="notice-content">{{ currBracelet.step }}步</div>
      </div>
      <div style="display: flex;align-items: center;margin-top: 2vh;justify-content: center">
        <div style="display: flex;align-items: center;flex: 1;justify-content: flex-end">
          <el-image style="width: 25px;height: 25px;" :src="'../assets/user_bpm.png'" mode="contain">
          </el-image>
          <span class="notice-title">bpm</span>
        </div>
        <div class="notice-content">{{ currBracelet.bpm }}次</div>
      </div>
      <div style="display: flex;align-items: center;margin-top: 2vh;justify-content: center">
        <div style="display: flex;align-items: center;flex: 1;justify-content: flex-end">
          <el-image style="width: 25px;height: 25px;" :src="'../assets/user_temperature.png'"
                    mode="contain"
          >
          </el-image>
          <span class="notice-title">电量</span>
        </div>
        <div class="notice-content">{{ currBracelet.battery }}%</div>
      </div>
      <div
          v-if="currentAlert != null"
          style="display: flex;align-items: center;margin-top: 2vh;justify-content: center">
        <div style="display: flex;align-items: center;flex: 1;justify-content: flex-end">
          <el-image style="width: 25px;height: 25px;" :src="'../assets/user_consumption.png'"
                    mode="contain"
          >
          </el-image>
          <span class="notice-title">告警</span>
        </div>
        <div class="notice-content">
          {{ currentAlert }}
          <el-button @click="clearAlert"
                     type="warning"
                     style="margin-left: 10px;color: #FFFFFF">
            消除
          </el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="间隔设置" :visible.sync="gapDialogVisible" :show-close="false">
      <div style="display: flex;align-items: center;">
        <el-select
            v-model="alertGap"
        >
          <el-option
              v-for="(item,index) in ENUM_MAP.alertGap.list"
              :key="index"
              :label="item.text"
              :value="item.value"
          />
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="gapDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmClearAlert">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

import * as THREE from 'three'

const CryptoJS = require('crypto-js')

const AES_KEY = '!J0IBueLyO!kRHY6blpta3#UGIL0IOoq'

import { BoxLineGeometry } from 'three/examples/jsm/geometries/BoxLineGeometry'

const AXIOS_INSTANCE = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000,
  withCredentials: true
})

// console.error(window.innerWidth, window.innerHeight)
// console.error(window.outerWidth, window.outerHeight)

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { PointerLockControls } from 'three/examples/jsm/controls/PointerLockControls.js'
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls.js'
import { DragControls } from 'three/examples/jsm/controls/DragControls.js'
import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper'

import axios from 'axios'
import Interaction from 'three.interaction/src/interaction/Interaction.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { setToken } from '../utils/auth'
import TWEEN from 'three/examples/jsm/libs/tween.module'

let camera, scene, renderer, controls, dragControls, floorMesh, sphereMaterial, groundMesh, interaction

let bgOuterWidth = 200
let bgOuterHeight = 400
let bgThickness = 30
let bgRawSize = new THREE.Vector3()
let groundHeight = 0

let bgInnerWidth = 200
let bgInnerHeight = 400

let xMirror = false
let yMirror = false

let realInnerWidth = 12.600
let realInnerHeight = 23.060
let realOuterWidth = 16.400
let realOuterHeight = 29.460

const weight = 1
let division = 20
// let division = 48

const angle = -Math.PI / 2
// let realInnerWidth = 10000
// let realInnerHeight = 10000
// let realOuterWidth = 20000
// let realOuterHeight = 20000

let originX = -realOuterWidth / 2
let originY = realOuterHeight / 2
let originPoint

let realInnerRatio = realInnerHeight / realInnerWidth
let realOuterRatio = realOuterHeight / realOuterWidth
let scaleX = 1
let scale = new THREE.Vector3(1, 1, 1)

let normal = new THREE.Vector3()

let percentageX = 1
let percentageZ = 1

const NORMAL_COLOR = '#46cb00'
const TRANSPARENT_COLOR = 'rgba(255,255,255,0)'
const OFFLINE_COLOR = '#888888'
const WARN_COLOR = '#f3bc06'
const ALERT_COLOR = '#f30606'

const tweens = {}

let simulated = false

let raycaster, mouse

document.addEventListener('UniAppJSBridgeReady', function () {
  uni && uni.postMessage({
    data: {
      sync: true
    }
  })
})

window.setSimulated = value => {
  console.error('simulated', value)
  simulated = value
  uni && uni.postMessage({
    data: {
      simulated
    }
  })
}

export default {
  name: 'Pool',
  data: () => {
    return {
      empId: null,
      stadiumId: null,
      token: null,
      bracelets: {},
      currBracelet: {},
      gapDialogVisible: false,
      dialogVisible: false,
      alertGap: 0
    }
  },
  filters: {
    codeFilter(code) {
      if (code == null) {
        return
      }
      return code
      // // 将字符串按两个字符为一组分割成数组
      // const chunks = code.toUpperCase().match(/.{1,2}/g)
      //
      // // 反转数组中的每个元素
      // const reversedChunks = chunks.map(chunk => chunk.split('').join(''))
      //
      // // 反转整个数组并拼接成字符串
      // return reversedChunks.reverse().join('')
    },
  },
  computed: {
    currentAlert() {
      if (this.currBracelet == null || this.currBracelet.alertType <= 0) {
        return null
      }
      const array = []
      this.ENUM_MAP.alertRuleType.list.forEach(item => {
        if ((this.currBracelet.alertType & item.value) === item.value) {
          array.push(item.text)
        }
      })
      return array.join('|')
    }
  },
  created() {
  },
  mounted() {
    const params = this.$route.query
    console.error(params)
    if (params.token) {
      const decrypted = CryptoJS.AES.decrypt(decodeURIComponent(params.token), AES_KEY).toString(CryptoJS.enc.Utf8)
      console.log(decrypted)
      const result = JSON.parse(decrypted)

      if (result.token) {
        AXIOS_INSTANCE.interceptors.request.use(
            config => {
              config.headers['Authorization'] = `Bearer ${result.token}`
              return config
            },
            error => {
              console.error(error)
              return Promise.reject(error)
            }
        )
        setToken(result.token)

        if (result.stadiumId) {
          this.stadiumId = result.stadiumId
          this.setParams(result)
        } else {
          AXIOS_INSTANCE.get(`admin/stadium/current`).then(({
            data
          }) => {
            if (data) {
              this.stadiumId = data.id
              this.setParams(data)
            }
          })
        }
      }
    }
  },
  deactivated() {
    console.error('deactivated')
  },
  beforeDestroy() {
    this.socket && this.socket.close()
  },
  methods: {
    setParams(result) {
      xMirror = result.mirrorX || false
      yMirror = result.mirrorY || false

      realInnerWidth = parseFloat(result.innerWidth) || realInnerWidth
      realInnerHeight = parseFloat(result.innerHeight) || realInnerHeight
      realOuterWidth = parseFloat(result.outerWidth) || realOuterWidth
      realOuterHeight = parseFloat(result.outerHeight) || realOuterHeight

      this.empId = result.empId
      this.initMeasureParams()
    },
    initMeasureParams() {
      const that = this

      scene = new THREE.Scene()
      scene.background = new THREE.Color(0xaaaaaa)

      // 创建渲染器
      const renderer = new THREE.WebGLRenderer({ alpha: true })
      renderer.setSize(window.innerWidth, window.innerHeight)
      renderer.outputColorSpace = THREE.SRGBColorSpace
      this.$refs.container.appendChild(renderer.domElement)

      // 启用阴影
      renderer.shadowMap.enabled = true

      this.addLight()

      // 使用GLTFLoader加载gltf模型
      const loader = new GLTFLoader()
      loader.load('3d/swimming_pool/scene.gltf', (gltf) => {

        // 从gltf中获取模型
        const model = gltf.scene
        // console.error(model)

        const boundingBox = new THREE.Box3()
        const waterBoundingBox = new THREE.Box3()
        model.traverse((child) => {
          if (child.name === 'SwimmingPool') {
            scale = child.scale
          }
          if (child instanceof THREE.Mesh) {
            child.receiveShadow = true // 允许产生阴影

            // console.error(child)
            child.geometry.computeBoundingBox()
            boundingBox.expandByPoint(child.geometry.boundingBox.min)
            boundingBox.expandByPoint(child.geometry.boundingBox.max)

            if (child.name === 'SwimmingPool_WaterShader_0') {
              waterBoundingBox.expandByPoint(child.geometry.boundingBox.min)
              waterBoundingBox.expandByPoint(child.geometry.boundingBox.max)
            } else if (child.name === 'SwimmingPool_PoolSideTilesWhite_0') {
              groundMesh = child
            }
          }
        })
        // console.error(scale, '.....')

        boundingBox.getSize(bgRawSize)

        // 获取包围盒的中心点
        const center = new THREE.Vector3()
        boundingBox.getCenter(center)
        // console.error(center, '........')

        bgOuterWidth = bgRawSize.x * scale.x
        bgOuterHeight = bgRawSize.z * scale.z
        bgThickness = bgRawSize.y * scale.y
        groundHeight = groundMesh.geometry.boundingBox.max.y * scale.y
        const maxSize = Math.max(bgOuterWidth, bgOuterHeight)

        const size = new THREE.Vector3()
        waterBoundingBox.getSize(size)
        bgInnerWidth = size.x * scale.x
        bgInnerHeight = size.z * scale.z

        realInnerRatio = realInnerHeight / realInnerWidth
        const bgInnerRatio = bgInnerHeight / bgInnerWidth
        scaleX = bgInnerRatio / realInnerRatio
        console.error(scaleX, '....')
        model.scale.x = scaleX

        // 创建相机
        camera = new THREE.PerspectiveCamera(90, window.innerWidth / window.innerHeight, 0.1, 10000)
        camera.position.y = maxSize / 1.6

        // const helper = new THREE.CameraHelper(camera)
        // scene.add(helper)

        percentageX = bgInnerWidth / realInnerWidth * scaleX
        percentageZ = bgInnerHeight / realInnerHeight

        // // 创建平面的几何体
        // const planeGeometry = new THREE.PlaneGeometry(realOuterWidth * percentageX, realOuterHeight * percentageZ)
        // // 创建平面的基础材质
        // const planeMaterial = new THREE.MeshBasicMaterial({ color: 0x00ff00, side: THREE.DoubleSide }) // 颜色为绿色，双面渲染
        // // 创建平面 Mesh
        // const plane = new THREE.Mesh(planeGeometry, planeMaterial)
        // plane.rotateX(angle)
        // console.error(plane)
        // // 将平面添加到场景中
        // scene.add(plane)

        // 将模型添加到场景中
        scene.add(model)

        // that.addBorder()
        that.addOriginPoint()

        controls = new OrbitControls(camera, renderer.domElement)
        // controls.minDistance = maxSize / 2
        controls.maxDistance = maxSize
        controls.enableDamping = true
        controls.rotateSpeed = 0.25

        // dragControls = new DragControls(camera, renderer.domElement)

        window.addEventListener('resize', onWindowResize)

        interaction = new Interaction(renderer, scene, camera)

        that.getBracelets()

        // 渲染场景
        animate()

        // model.on('click', ev => {
        //   // 获取局部坐标
        //   let localPoint = ev.data.global
        //   console.error(originPoint.scale)
        //
        //   // 创建一个新的 Vector3 实例来表示局部坐标
        //   let localVector = new THREE.Vector3(localPoint.x, localPoint.y, localPoint.z)
        //
        //   // 确保世界矩阵是最新的
        //   originPoint.updateMatrixWorld(true)
        //   // 将局部坐标转换为世界坐标
        //   let worldVector = originPoint.worldToLocal(localVector)
        //
        //   console.log('世界坐标:', worldVector)
        //
        //   // that.bracelets['C0012C0D612A'].location = this.genLocation(localVector.x, localVector.y)
        //   // that.updateBracelet(that.bracelets['C0012C0D612A'])
        // })

        // 设置射线投射和鼠标变量
        raycaster = new THREE.Raycaster()
        mouse = new THREE.Vector2()

        // 监听鼠标点击事件
        window.addEventListener('click', this.onDocumentMouseDown, false)

      })

      function onWindowResize() {

        camera.aspect = window.innerWidth / window.innerHeight
        camera.updateProjectionMatrix()

        renderer.setSize(window.innerWidth, window.innerHeight)

      }

      function animate() {

        requestAnimationFrame(animate)

        controls.update()

        render()

      }

      function render() {

        const radius = bgOuterWidth / division
        for (let i = 0; i < originPoint.children.length; i++) {

          const object = originPoint.children[i]

          for (let j = i + 1; j < originPoint.children.length; j++) {

            const object2 = originPoint.children[j]

            normal.copy(object.position).sub(object2.position)

            const distance = normal.length()

            if (distance < 2 * radius) {

              normal.multiplyScalar(0.5 * distance - radius)

              object.position.sub(normal)
              object2.position.add(normal)

              normal.normalize()
            }
          }
        }

        for (let key of Object.keys(tweens)) {
          tweens[key].update()
        }
        renderer.render(scene, camera)
      }
    },
    onDocumentMouseDown(event) {
      // event.preventDefault()

      if (simulated) {
        // 计算鼠标在屏幕上的位置
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1

        // 使用射线投射计算点击位置
        raycaster.setFromCamera(mouse, camera)

        // 检查是否点击了场景中的对象
        const intersects = raycaster.intersectObjects(scene.children)

        if (intersects.length > 0) {
          console.log('点击位置：', intersects[0].point)

          let vector = new THREE.Vector3(intersects[0].point.x, intersects[0].point.y, intersects[0].point.z)

          originPoint.updateMatrixWorld(true)
          // 将局部坐标转换为世界坐标
          let worldVector = originPoint.worldToLocal(vector)

          console.log('局部坐标:', worldVector)

          const location = this.genLocation(worldVector.x, worldVector.z)
          uni && uni.postMessage({
            data: {
              location
            }
          })
        }
      }
    },
    addLight() {
      // 添加光源
      // const light = new THREE.AmbientLight(0xFFFFFF, 4)
      // scene.add(light)

      const directionalLight = new THREE.DirectionalLight(0xffffff, 3)
      directionalLight.castShadow = true
      directionalLight.position.set(0, 1000, 0)
      scene.add(directionalLight)

      const height = 1000
      const intensity = 1
      for (let i = 0; i < 3; i++) {
        const j = 1 << i
        const { x, y, z } = { x: (j & 1) * height, y: ((j & 2) >> 1) * height, z: ((j & 4) >> 2) * height }
        // console.error(x, y, z)
        const directionalLight = new THREE.DirectionalLight(0xffffff, intensity)
        directionalLight.position.set(x, y, z)
        scene.add(directionalLight)

        const directionalLight1 = new THREE.DirectionalLight(0xffffff, intensity)
        directionalLight1.position.set(-x, -y, -z)
        scene.add(directionalLight1)
      }
    },
    addShadowPlane() {
      const mesh = new THREE.Mesh(new THREE.PlaneGeometry(bgOuterWidth, bgOuterHeight), new THREE.MeshPhongMaterial({
        color: 0xffffff,
        depthWrite: false
      }))
      mesh.rotation.x = angle
      mesh.receiveShadow = true
      scene.add(mesh)
    },
    addOriginPoint() {
      const radius = bgOuterWidth / division

      // 创建旗杆
      const width = radius / division
      var cylinderGeometry = new THREE.CylinderGeometry(width, width, radius * 2, 64) // 参数：底部半径，顶部半径，高度，分段数
      var cylinderMaterial = new THREE.MeshLambertMaterial({ color: 0xffffff, transparent: true, opacity: 0.5 })
      var cylinder = new THREE.Mesh(cylinderGeometry, cylinderMaterial)
      cylinder.position.x = (xMirror ? -1 : 1) * originX * percentageX
      cylinder.position.y = groundHeight + radius
      cylinder.position.z = (yMirror ? -1 : 1) * originY * percentageZ
      scene.add(cylinder)

      originPoint = cylinder

      // 创建旗帜（三角形）
      var flagGeometry = new THREE.ConeGeometry(radius / 3, radius, 3) // 使用锥形几何体
      var flagMaterial = new THREE.MeshLambertMaterial({ color: 0x00bcd4, side: THREE.DoubleSide })
      var flag = new THREE.Mesh(flagGeometry, flagMaterial)
      flag.position.y = groundHeight + radius * 2
      flag.position.x = (xMirror ? -1 : 1) * originX * percentageX
      flag.position.z = (yMirror ? -1 : 1) * originY * percentageZ
      scene.add(flag)

      // 创建球体几何体
      // const geometry = new THREE.IcosahedronGeometry(radius, 3)
      // originPoint = new THREE.Mesh(geometry, new THREE.MeshBasicMaterial({
      //   color: TRANSPARENT_COLOR,
      //   transparent: true, opacity: 0.5
      // }))
      // originPoint.position.y = groundHeight + radius
      // originPoint.position.x = (xMirror ? -1 : 1) * originX * percentageX
      // originPoint.position.z = (yMirror ? -1 : 1) * originY * percentageZ
      //
      // scene.add(originPoint)

      originPoint.on('click', e => {
        // console.error(e)
      })

      // const geometry1 = new THREE.IcosahedronGeometry(bgOuterWidth / 32, 3)
      // const mesh1 = new THREE.Mesh(geometry1, new THREE.MeshLambertMaterial({ color: NORMAL_COLOR }))
      // mesh1.position.y = 20
      // mesh.add(mesh1)

    },
    addBorder() {
      // 侧面是白色瓷砖
      const size = bgOuterWidth / 50
      const texture = new THREE.TextureLoader().load('3d/swimming_pool/textures/PoolSideTilesWhite_baseColor.png')
      const material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide })
      const geometry = new THREE.PlaneGeometry(size, size)
      const mesh = new THREE.Mesh(geometry, material)
      mesh.rotateX(angle)
      mesh.position.y = groundHeight
      mesh.position.x = bgOuterWidth / 2 + size / 2
      mesh.position.z = bgOuterHeight / 2 + size / 2
      scene.add(mesh)
    },
    addBracelets(list) {
      if (list && list.length > 0) {
        for (const item of list) {
          this.addBracelet(item)
        }
      }
    },
    addBracelet(item) {
      if (item == null || item.code == null || item.location == null) {
        return
      }
      this.genXy(item)
      console.error(item.code, item.x, item.y)
      if (item.x == null && item.y == null) {
        return
      }
      const radius = bgOuterWidth / division
      const geometry = new THREE.SphereGeometry(radius, 64, 64)
      const color = item.online ? (item.alertType != null ? ALERT_COLOR : NORMAL_COLOR) : OFFLINE_COLOR
      const object = new THREE.Mesh(geometry, new THREE.MeshLambertMaterial({ color }))

      object.position.x = item.x
      object.position.z = item.y

      object.userData.code = item.code
      object.userData.raw = item
      object.userData.color = color

      // var textGeometry = new THREE.TextGeometry("Hello, Three.js!", {
      //   font: font,
      //   size: 0.2,
      //   height: 0.1,
      // });
      //
      // // 将 TextGeometry 转换为 BufferGeometry
      // var bufferGeometry = new THREE.BufferGeometry().fromGeometry(textGeometry);
      //
      // var textMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });
      // var textMesh = new THREE.Mesh(bufferGeometry, textMaterial);
      // textMesh.position.set(-1, 0, 2); // 调整文字位置
      // scene.add(textMesh);

      const that = this
      object.on('click', e => {
        // console.error(e)
        if (e && e.target && e.target instanceof THREE.Mesh) {
          const bracelet = that.bracelets[e.target.userData.code]
          if (bracelet) {
            that.currBracelet = bracelet
            that.dialogVisible = true
          }
        }
      })

      item.objectId = object.id
      originPoint.add(object)

      this.bracelets[item.code] = item
    },
    updateBracelet(item) {
      if (item == null || item.code == null || item.location == null) {
        return
      }
      if (this.bracelets[item.code] != null) {
        if (this.currBracelet && this.currBracelet.code === item.code) {
          for (let key of Object.keys(item)) {
            this.$set(this.currBracelet, key, item[key])
          }
          console.error(this.currBracelet.bpm)
        }
        const bracelet = this.bracelets[item.code]
        if (bracelet.objectId != null) {
          const object = originPoint.getObjectById(bracelet.objectId)
          if (object != null) {
            const color = item.online ? (item.alertType != null ? ALERT_COLOR : NORMAL_COLOR) : OFFLINE_COLOR
            if (object.userData.color !== color) {
              object.material = new THREE.MeshLambertMaterial({ color })
              object.color = color
            }

            this.genXy(item)
            console.error(item.code, item.x, item.y)
            if (item.x == null && item.y == null) {
              return
            }
            // object.position.x = item.x
            // object.position.z = item.y

            object.userData.code = item.code

            // 创建 Tween 动画
            tweens[item.code] = new TWEEN.Tween({ x: object.position.x, y: object.position.y, z: object.position.z })
                .to({ x: item.x, y: object.position.y, z: item.y }, 2000) // 持续时间 2000 毫秒
                .easing(TWEEN.Easing.Quadratic.Out) // 可选的缓动函数
                .onUpdate(({ x, y, z }) => {
                  // 在每一帧更新 mesh 的位置
                  object.position.set(x, y, z)
                })
                .start() // 启动动画
          }
          item.objectId = object.id
          this.bracelets[item.code] = item
        } else {
          this.addBracelet(item)
        }
      } else {
        this.addBracelet(item)
      }
    },
    genXy(item) {
      if (item && item.location) {
        const arr = item.location.split(',')
        if (arr.length === 2) {
          item.x = (xMirror ? -1 : 1) * arr[0] * percentageX * weight
          item.y = (yMirror ? -1 : 1) * (-arr[1] * percentageZ * weight)
          // console.error(item.x, item.y)
        }
      }
    },
    genLocation(x, y) {
      return [(xMirror ? -1 : 1) * x / percentageX / weight, -(yMirror ? -1 : 1) * y / percentageZ / weight].join(',')
    },
    getBracelets() {
      const that = this
      that.bracelets = {}
      AXIOS_INSTANCE.get(`lifeguard/bracelets`, {
        params: {
          stadiumId: this.stadiumId,
          empId: this.empId,
        }
      }).then(({
        data
      }) => {
        // console.error(data)
        that.addBracelets(data)
        that.monitor()
      })
    },
    clearAlert() {
      this.gapDialogVisible = true
    },
    confirmClearAlert() {
      this.gapDialogVisible = false
      AXIOS_INSTANCE.post(`lifeguard/clear_alert`, {
        code: this.currBracelet.code,
        seconds: this.alertGap,
        stadiumId: this.stadiumId,
        empId: this.empId,
      }).then(({
        data
      }) => {
        console.error(data)
      })
    },
    monitor() {
      const root = this.$root
      const that = this
      root.ws.connect({
        statusListener: ({
          connected
        }) => {
          if (connected) {
            if (this.stadiumId || this.empId) {
              root.ws.send(JSON.stringify({
                stadiumId: this.stadiumId,
                empId: this.empId
              }))
            }
          }
        },
        globalListener: (data) => {
          // console.log(data)
          const info = data
          if (info != null) {
            if (info.type == this.ENUM_MAP.webSocketMessageResponseType.nameMap.BRACELET.value) {
              console.error(info.data.bpm)
              that.updateBracelet(info.data)
            } else if (info.type == this.ENUM_MAP.webSocketMessageResponseType.nameMap.BRACELETS.value) {
              if (info.data && info.data.length > 0) {
                for (let item of info.data) {
                  that.updateBracelet(item)
                }
              }
            } else if (info.type == this.ENUM_MAP.webSocketMessageResponseType.nameMap.LEAVE.value) {
              if (info.data) {
                const code = info.data
                const bracelet = this.bracelets[code]
                if (bracelet.objectId != null) {
                  const object = originPoint.getObjectById(bracelet.objectId)
                  if (object != null) {
                    originPoint.remove(object)
                  }
                }
                delete this.bracelets[code]
              }
            }
          }
        }
      })
    },
  }
}
</script>

<style>
.el-dialog {
  border-radius: 10px;
  margin-top: 30vh !important;
  /*height: 30vh !important;*/
}

.el-dialog__header {
  text-align: center;
}

.el-dialog__title {
  font-size: 24px;
}

.notice-title {
  font-size: 18px;
  font-weight: 300;
  margin-left: 5px;
}

.notice-content {
  padding-left: 2vw;
  flex: 1;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  align-items: center;
}

</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
html,
body {
  margin: 0;
  padding: 0;
  color: #999;
  font-size: 16px;
}

div.invitation {
  background-color: #ffeda1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.head {
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% auto
}

div > img {
  display: block;
  width: 100%;
  line-height: 0;
  padding: 0;
  margin: 0;
}

div.invitation > .register {
  flex: 0 1;
  display: flex;
  margin: 0 15px 15px 15px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 0 10px #ccc;
  flex-direction: column;
  width: calc(100% - 30px);
  padding: 20px 0;
}

div.invitation > .info {
  flex: 0 1;
  display: flex;
  margin: 0 15px 15px 15px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 0 10px #ccc;
  flex-direction: column;
  width: calc(100% - 30px);
  padding: 20px 0;
  align-items: center;
  justify-content: center;
}

div.info img {
  width: 50px;
}

div.invitation input {
  border: 0;
  padding: 0;
  margin: 0;
  outline: none;
  width: 100%;
}

div.input {
  flex: 0 1;
  min-height: 50px;
  margin: 15px 15px 0 15px;
  display: flex;
  background: #f5f5f5;
  border-radius: 10px
}

div.input:not(first-child) {
  margin-top: 20px;
}

div.invitation input {
  color: #bbbbbb;
  margin: 15px;
  font-size: 14px;
  background: none;
}

div.input-error {
  border-bottom: 2px solid red
}

div.input-short {
  border-bottom: 2px solid #cccccc;
}

div.get-code {
  flex: 0 1;
  min-height: 56px;
  min-width: 100px;
  display: flex;
  background: #f5f5f5;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  font-size: 14px;
  border-radius: 10px;
  font-weight: bold;
}

div.protocol {
  text-align: center;
  font-size: 10px;
}

div.protocol > span {
  font-weight: bold;
  text-decoration: underline;
  color: rgb(255, 202, 59);
}

div.register-button {
  background-color: #ccc;
  color: #fff;
  display: flex;
  margin: 20px 15px 15px 15px;
  border-radius: 25px;
  align-items: center;
  justify-content: center;
  flex: 0 1;
  min-height: 50px;
  letter-spacing: 1rem;
}

div.download-button {
  background-color: rgb(255, 202, 59);
  color: #fff;
  display: flex;
  margin: 20px 15px 15px 15px;
  border-radius: 25px;
  align-items: center;
  justify-content: center;
  flex: 0 1;
  min-height: 50px;
  letter-spacing: 0.5rem;
}

div.register-enabled {
  background-color: rgb(255, 202, 59);
}

div.registered > img {
  margin-bottom: 20px;
}

div.registered-text {
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: rgb(255, 202, 59);
}

div.recruit {
  background-color: rgb(255, 202, 59);
  color: #fff;
  display: flex;
  margin: 5px 15px 15px 15px;
  border-radius: 25px;
  align-items: center;
  justify-content: center;
  flex: 0 1;
  min-height: 56px;
  width: calc(100% - 30px);
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #666;
}

input:-moz-placeholder, textarea:-moz-placeholder {
  color: #666;
  font-size: 16px;
}

input::-moz-placeholder, textarea::-moz-placeholder {
  color: #666;
  font-size: 16px;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #666;
  font-size: 16px;
}

</style>

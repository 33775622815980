import { getToken } from '@/utils/auth'

const install = (Vue, vm) => {
  const ws = {
    socketTask: null,
    statusListener: null,
    listeners: {},
    globalListener: null,
    timeout: null,
    connected: false,
    connecting: false,
    close: () => {
      ws.socketTask && ws.socketTask.close()
      ws.socketTask = null
      ws.connected = false
    },
    send: (data) => {
      ws.socketTask && ws.socketTask.send(data)
    },
    addStatusListener: callback => {
      ws.statusListener = callback
    },
    removeStatusListener: () => {
      ws.statusListener = null
    },
    addGlobalListener: callback => {
      ws.globalListener = callback
    },
    removeGlobalListener: () => {
      ws.globalListener = null
    },
    addListener: (type, callback) => {
      ws.listeners[type] = callback
    },
    removeListener: type => {
      ws.listeners[type] = null
    },
    removeListeners: () => {
      ws.listeners = {}
    },
    connectInternal: ({
      statusListener,
      globalListener,
      listeners,
      reconnect = true
    }) => {
      if (getToken() == null) {
        ws.connecting = false
        ws.connected = false
        ws.fireStatusChanged()
        ws.socketTask && ws.socketTask.close()
        ws.socketTask = null
        return
      }

      statusListener && ws.addStatusListener(statusListener)
      globalListener && ws.addGlobalListener(globalListener)
      if (listeners) {
        for (const listener of listeners) {
          ws.addListener(listener.type, listener.callback)
        }
      }

      if (ws.socketTask == null && !ws.connecting) {
        ws.connecting = true
        ws.fireStatusChanged()

        ws.socketTask = new WebSocket(process.env.VUE_APP_BASE_SOCKET_API, getToken())
        // 监听socket连接
        ws.socketTask.onopen = res => {
          ws.connected = true
          ws.connecting = false
          ws.fireStatusChanged()
          console.log('WebSocket连接已打开', JSON.stringify(res))
        }
        // 监听socket错误信息
        ws.socketTask.onerror = err => {
          console.error('WebSocket出现问题', JSON.stringify(err))
          ws.reconnect(reconnect)
        }

        ws.socketTask.onclose = res => {
          console.log('WebSocket已关闭', JSON.stringify(res))
          ws.reconnect(reconnect)
        }

        // 监听socket消息
        ws.socketTask.onmessage = res => {
          if (res.data) {
            const result = JSON.parse(res.data)
            ws.globalListener && ws.globalListener(result)
            if (result.type != null) {
              const type = result.type
              ws.listeners[type] && ws.listeners[type](result)
            }
          }
        }
      }

      ws.fireStatusChanged()
    },
    connect: (params) => {
      params = params || {}
      ws.connectInternal(params)
    },
    reconnect: (reconnect) => {
      ws.connected = false
      ws.connecting = false
      ws.socketTask = null
      ws.fireStatusChanged()
      if (reconnect) {
        ws.timeout && clearTimeout(ws.timeout)
        ws.timeout = setTimeout(() => ws.connect({ reconnect }), 5000)
      }
    },
    fireStatusChanged: () => {
      ws.statusListener && ws.statusListener({
        connected: ws.connected,
        connecting: ws.connecting
      })
    }
  }

  vm.ws = ws
}

export default {
  install
}

import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(ElementUI, { size: 'mini', zIndex: 3000 })

import VueRouter from 'vue-router'

Vue.use(VueRouter)

import routes from '@/routes'

const router = new VueRouter({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes // (缩写) 相当于 routes: routes
})

import Mint from 'mint-ui'
import 'mint-ui/lib/style.css'

Vue.use(Mint)

Vue.config.productionTip = false

import * as ENUMS from './utils/enums.js'

Vue.prototype.ENUM_MAP = ENUMS.ENUM_MAP
Vue.prototype.ENUMS = ENUMS

const app = new Vue({
  el: '#app',
  router,
  render: h => h(App)
})

import ws from './utils/websocket.js'

Vue.use(ws, app)

app.$mount()

const resolveEnumSet = (enumSet) => {
  const valueMap = {}
  const nameMap = {}
  const list = []
  const array = enumSet.split('|')
  array.forEach((a, index) => {
    const r = a.split(':')
    const r1 = r[1].split(',')
    let value, name, text
    if (r1.length > 1) {
      value = parseInt(r[0])
      name = r1[0]
      text = r1[1]
    } else {
      value = parseInt(r[0])
      name = r[1]
      text = r[1]
    }
    valueMap[value] = {
      name,
      text,
      index
    }
    nameMap[name] = {
      value,
      text,
      index
    }
    list.push({
      value,
      name,
      text
    })
  })
  return {
    valueMap,
    nameMap,
    list
  }
}

const enums = [{
  text: '1:BRACELET,手环|2:BRACELETS,手环列表|3:SWIMMING,游泳|4:ALERT,告警|5:LEAVE,结束游泳',
  name: 'webSocketMessageResponseType'
}, {
  text: '1:BPM_LOW,心率过低|2:BPM_HIGH,心率过高|4:BPM_FLUCTUATION,心率波动',
  name: 'alertRuleType'
}, {
  text: '0:ALWAYS,一直提醒|30:THIRTY_SECONDS,30秒|60:ONE_MINUTE,1分钟|300:FIVE_MINUTES,5分钟',
  name: 'alertGap'
}]

const ENUM_MAP = {}
enums.forEach(item => {
  const map = resolveEnumSet(item.text)
  ENUM_MAP[item.name] = {
    map,
    ...map
  }
  ENUM_MAP[item.name].textFor = (value) => map.valueMap && map.valueMap[value] && map.valueMap[value].text ||
    ''
})

export {
  ENUM_MAP,
}
